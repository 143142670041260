export const IichiEnv = {
  DEV: 'dev',
  STAGE: 'stage',
  PROD: 'prod',
} as const;
export type IichiEnv = (typeof IichiEnv)[keyof typeof IichiEnv];

export interface User {
  credentials: string[];
}

export interface Member {
  alias: string;
  member_id: number;
  member_name: string;
  email: string;
  picture_path: string;
  slug: string;
  a: boolean;
  b: boolean;
  permissions: number[];
}

export interface Shop {
  is_on_vacation: boolean;
}

export interface Topic {
  type: 'confirmationRequired' | 'emailConfirmed' | 'info' | 'permissionRequired' | 'signupComplete' | 'warning' | 'welcome';
  props?: {
    email?: string;
    fromNative?: boolean;
    text?: string;
    permissionId?: number;
  };
}

const Pallet: {
  iichiEnv: IichiEnv;
  User?: User;
  Member?: Member;
  Topics?: Topic[];
  Item?: {
    itemId: number;
  };
  InstagramShopping?: {
    reload: boolean;
  };
  NoteStore?: {
    reload: boolean;
  };
  Payment?: {
    tokenApiKey: string;
  };
  microcms?: {
    apiKey: string;
  };
  Yappli?: {
    token: string;
  };
} = window.Pallet || { iichiEnv: IichiEnv.PROD };

export default {
  Pallet, // サーバー側から受け取る値
};
