import '@/js/dist/www.iichi.com/common';
import Vue from 'vue';
import { pinia } from '@/js/lib/store';
import { useItemStore } from '@/js/lib/store/item';
import { uniq } from '@/js/lib/helper/array';
import Slider from '@/js/components/common/ui/slider.vue';
import LikeButtonSmall from '@/js/components/www.iichi.com/organisms/likeButtonSmall.vue';

const itemStore = useItemStore(pinia);

document.addEventListener('DOMContentLoaded', () => {
  const itemIds = Array.from(document.querySelectorAll('like-button-small'), (el) => Number(el.getAttribute(':item-id')));
  itemStore.initLikedData(uniq(itemIds));

  new Vue({
    el: '#content',
    components: {
      Slider,
      LikeButtonSmall,
    },
  });
});
