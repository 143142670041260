import { readonly, ref } from 'vue';
import { defineStore } from 'pinia';
import { type Shop } from '@/js/lib/_env';
import { credentials } from '@/js/lib/api/user';
import { useUserCredentialStore } from './user-credential';

export const useShopStore = defineStore('shop', () => {
  const { hasCredential } = useUserCredentialStore();
  const isOnVacation = ref(false);
  function assignShop(shop: Shop) {
    isOnVacation.value = hasCredential(credentials.seller) ? Boolean(shop.is_on_vacation) : false;
  }

  return {
    isOnVacation: readonly(isOnVacation),
    assignShop,
  };
});
