import env, { type User, type Member, type Shop, type Topic } from './_env';
import { pinia } from './store';
import { useUserStore } from './store/user';
import { useShopStore } from './store/shop';

export { IichiEnv } from './_env';
export { CdnDomain } from './_config';

interface Options {
  iichiEnv: string;
  User?: User;
  Member?: Member;
  Shop?: Shop;
  Topics?: Topic[];
}

export class Common {
  public env: typeof env;

  constructor() {
    this.env = env;
  }

  initialize(options: Options) {
    const userStore = useUserStore(pinia);
    const shopStore = useShopStore(pinia);
    userStore.updateCredential(options.User.credentials);
    if (options.Member) {
      userStore.assignMember(options.Member);
      userStore.updatePermission(options.Member.permissions);
      shopStore.assignShop(options.Shop);
    }
  }
}

// 同一インスタンスを使用するためwindow下に保持
const common = window.common === undefined ? new Common() : window.common;
window.common = common;

export default common;
