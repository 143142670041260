import axios from '@/js/lib/axios';
import { updateToken } from '@/js/lib/api/update_token';

export const agree = async ({ settingId }: { settingId: number }): Promise<''> => {
  const token = await updateToken();
  const params = new URLSearchParams();
  params.append('setting_id', String(settingId));
  params.append('token', token);
  const res = await axios.post('/api/account/agree', params);
  return res.data;
};

export const confirmEmail = async ({ next }: { next: string }): Promise<''> => {
  const token = await updateToken();
  const params = new URLSearchParams();
  params.append('next', next);
  params.append('token', token);
  const res = await axios.post('/api/account/confirm-email', params);
  return res.data;
};

export const quitAccount = async (): Promise<''> => {
  const token = await updateToken();
  const res = await axios.delete(`/api/my/account`, {
    data: {
      token,
    },
  });
  return res.data;
};
